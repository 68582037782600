import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform-service.service';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { MatomoTracker } from 'ngx-matomo';
import { FeatureFlagsService } from 'src/services/feature-flags/feature-flags.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isLogin = false;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private contextService: ContextService,
    private helperFunctions: HelperFunctionsService,
    private plaformService: PlatformService,
    private featureFlags: FeatureFlagsService
  ) {
    let referrerHost = '';
    if (document.referrer) {
      referrerHost = new URL(document.referrer).hostname;
    }
    if (referrerHost) {
      if (referrerHost !== window.location.hostname) {
        this.contextService.referrer = referrerHost;
      }
    }

    this.authenticationService.initializeDone.subscribe(async (status) => {
      if (status) {
        await this.plaformService
          .getUserProfile()
          .then((resp) => {
            resp.features.forEach((feature: { name: string }) => {
              this.featureFlags.setFeatureEnabled(feature.name);
            });
            if (resp.features.some((x: any) => x.name === 'consultantlist')) {
              const findex = this.contextService.navs.dashboard.findIndex(
                (x) => x.slug === 'consultants'
              );
              this.contextService.navs.dashboard[findex].allowed = true;
            }
            if (resp.features.some((x: any) => x.name === 'inbox')) {
              const findIndox = this.contextService.navs.dashboard.findIndex(
                (x) => x.slug === 'inbox'
              );
              this.contextService.navs.dashboard[findIndox].allowed = true;
              const findAccept = this.contextService.navs.dashboard.findIndex(
                (x) => x.slug === 'accepted'
              );
              this.contextService.navs.dashboard[findAccept].allowed = true;

              //Is only used by Kim, but it gives a cross platform error. TODO need to fix on environment.
              // this.plaformService.getStatusCount();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      this.isLogin = status;
    });
  }

  title = this.contextService.title();
  signin() {
    this.authenticationService.signin();
  }
  signout() {
    this.authenticationService.signout();
  }

  ngOnInit() {
    this.authenticationService.loginDisplay.subscribe({
      next: (val) => {
        this.router.events.subscribe({
          next: (value) => {
            if (value instanceof NavigationEnd) {
              if (value.url === '/' && val) {
                const redirect = sessionStorage.getItem('redirect');
                if (redirect) {
                  sessionStorage.removeItem('redirect');
                  this.helperFunctions.route([redirect]);
                } else {
                  this.helperFunctions.route(['/requests']);
                }
              }
              const arr = value.url.split('/');
              this.contextService.matchingSlug = arr[arr.length - 1];
            }
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });

    this.authenticationService.initialize();
  }
}
