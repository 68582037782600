import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, FilterData, option } from 'src/interface/shared.interface';
import { FeatureFlagsService } from 'src/services/feature-flags/feature-flags.service';
import { HelperFunctionsService } from 'src/services/helperFunctions/helper-functions.service';
import { ContextService } from 'src/services/platform/context.service';
import { PlatformService } from 'src/services/platform/platform-service.service';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-filter-list',
  templateUrl: './filter-list.component.html',
  styleUrls: ['./filter-list.component.scss'],
})
export class FilterListComponent implements OnChanges {
  @Input() disable: boolean;
  @Input() filterLevel: boolean;
  @Input() matchId: string | undefined;
  @Input() iterateId: number | null;
  @Input() selectedSearchType: number;
  @Input() modal: boolean;
  @Input() selectedCvType: number;
  @Input() selectedNav: string;

  filters: filter[] = [
    {
      name: 'Preferred work location',
      option: [],
      disable: false,
      type: 'location',
      hide: this.featureFlags.isFeatureEnabled('prefLocation'),
    },
    {
      disable: false,
      name: 'Keywords',
      option: [],
      type: 'keywords',
      hide: this.featureFlags.isFeatureEnabled('keywords'),
    },
    { disable: false, name: 'Availability', option: [], type: 'availability' },
    {
      disable: false,
      name: 'Worked with 7N',
      option: [],
      type: 'workedwith7n',
    },
    { disable: false, name: '7N status', option: [], type: 'status' },
    {
      disable: false,
      name: 'Consultant status',
      option: [],
      type: 'relationstatus',
    },
    {
      disable: false,
      title: 'Team',
      name: 'Team',
      option: [],
      type: 'teamfilter',
    },
  ];
  selectedFilters: option[] = [];
  availability: {
    option: option;
    range?: { startDate: Date; endDate: Date };
  } = { option: { id: '-1', displayName: '', type: '' } };
  expandFilter: number[] = [0, 1, 2];
  filterData: FilterData = this.contextService.filterData;

  isChecked: boolean;
  mapAvailable = [
    { id: '0available', value: 'now', displayName: 'Now' },
    { id: '1available', value: 'next30days', displayName: 'Next 30 days' },
    { id: '2available', value: 'next60days', displayName: 'Next 60 days' },
    { id: '3available', value: 'range', displayName: 'Custom range' },
  ];
  queryParams: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contextService: ContextService,
    public featureFlags: FeatureFlagsService,
    private helperFunctions: HelperFunctionsService,
    private platformService: PlatformService
  ) {
    if (this.route.queryParams) {
      this.route.queryParams.subscribe((params) => {
        const object = { ...params };
        this.selectedCvType = object['cvtype']
          ? JSON.parse(object['cvtype'])
          : 0;
        if (Object.keys(object).length === 0 && !this.queryParams) {
          this.queryParams = object;
          return;
        }
        this.queryParams = object;
        setTimeout(() => {
          this.queryFilter();
        });
      });
    }
  }

  isFilterAvailable(type: string) {
    const index = [1, 2];
    const types = ['teamfilter', 'relationstatus'];
    if (index.includes(this.selectedSearchType) && types.includes(type)) {
      return false;
    }
    return true;
  }

  checkIfFeatureFlags(feature: string) {
    return this.featureFlags.isFeatureEnabled(feature);
  }
  async clearFilter(i: number) {
    this.filters[i].option = [];
    if (this.filters[i].type === 'teamfilter') {
      delete this.queryParams['team'];
      delete this.queryParams['country'];
      delete this.queryParams['office'];
    } else {
      delete this.queryParams[this.filters[i].type];
    }
    if (this.filters[i].type === 'availability') {
      delete this.queryParams.range;
      this.availability = { option: { id: '-1', displayName: '', type: '' } };
    }
    if (this.filters[i].type === 'workedwith7n') {
      this.isChecked = false;
    }

    await this.router.navigate([], {
      queryParams: this.queryParams,
    });
    this.setFilteringList();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectedSearchType']) {
      this.filters[1].hide = this.selectedSearchType !== 2;
    }
  }

  async queryFilter() {
    const findIndex = this.filters.findIndex((x) => x.type === 'teamfilter');
    if (Object.keys(this.queryParams).includes('team')) {
      const team = this.queryParams['team']
        .split(',')
        .filter((x: string) =>
          this.filterData?.team?.some((z) => z.name === x)
        );
      const option: option[] = this.filters[findIndex].option.filter(
        (x) => x.type !== 'team'
      );

      team.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'team' });
      });

      this.getFilterFunc(option, 'team');
    } else {
      this.filters[findIndex].option = this.filters[findIndex].option.filter(
        (x) => x.type !== 'team'
      );
    }

    if (Object.keys(this.queryParams).includes('country')) {
      const location = this.queryParams['country']
        .split(',')
        .filter((x: string) =>
          this.filterData?.location?.some((z) => z.displayName === x)
        );
      const option: option[] = this.filters[findIndex].option.filter(
        (x) => x.type !== 'country'
      );
      location.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'country' });
      });
      this.getFilterFunc(option, 'country');
    } else {
      this.filters[findIndex].option = this.filters[findIndex].option.filter(
        (x) => x.type !== 'country'
      );
    }

    if (this.queryParams['office']) {
      const office = this.queryParams['office']
        .split(',')
        .filter((x: string) => {
          return this.filterData?.location?.some((z) => z.subNames.includes(x));
        });

      const option: option[] = this.filters[findIndex].option.filter(
        (x) => x.type !== 'office'
      );

      office.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'office' });
      });
      this.getFilterFunc(option, 'office');
    } else {
      this.filters[findIndex].option = this.filters[findIndex].option.filter(
        (x) => x.type !== 'office'
      );
    }

    if (Object.keys(this.queryParams).includes('status')) {
      const statuses = this.queryParams['status']
        .split(',')
        .filter((x: string) => this.filterData?.statuses?.some((z) => z === x));

      const option: option[] = [];
      statuses.forEach((x: string) => {
        option.push({ id: uuidv4(), displayName: x, type: 'status' });
      });
      this.getFilterFunc(option, 'status');
    } else {
      const findIndex = this.filters.findIndex((x) => x.type === 'status');
      this.filters[findIndex].option = [];
    }

    if (Object.keys(this.queryParams).includes('relationstatus')) {
      const relationstatus: any[] = [];
      this.queryParams['relationstatus'].split(',').forEach((x: string) => {
        const findex = this.filterData.tiers?.findIndex(
          (z) => z.status.contractStatus === x
        );
        if (findex !== -1 && this.filterData.tiers && findex !== undefined) {
          relationstatus.push({
            relationStatuses:
              this.filterData?.tiers[findex].status.contractStatus,
            displayName: this.filterData?.tiers[findex].status.description,
          });
        }
        return this.filterData?.tiers?.some((z) =>
          z.status.contractStatus.includes(x)
        );
      });

      const option: option[] = [];
      relationstatus.forEach((x: any) => {
        option.push({
          id: uuidv4(),
          displayName: x.relationStatuses,
          name: x.displayName,
          type: 'relationstatus',
        });
      });
      this.getFilterFunc(option, 'relationstatus');
    } else {
      const findIndex = this.filters.findIndex(
        (x) => x.type === 'relationstatus'
      );
      this.filters[findIndex].option = [];
    }

    if (Object.keys(this.queryParams).includes('workedwith7n')) {
      const value = this.queryParams['workedwith7n'] !== false;

      const findIndex = this.filters.findIndex(
        (x) => x.type === 'workedwith7n'
      );
      this.filters[findIndex].option = this.getWorkAs7N(value);
    } else {
      const findIndex = this.filters.findIndex(
        (x) => x.type === 'workedwith7n'
      );
      if (findIndex !== -1) {
        this.filters[findIndex].option = this.getWorkAs7N(false);
      }
    }

    if (Object.keys(this.queryParams).includes('keywords')) {
      if (this.queryParams['keywords']) {
        const split = this.queryParams['keywords'].split(',');
        const option = split.map((x: string) => {
          return { id: uuidv4(), displayName: x, type: 'keywords' };
        });
        this.getFilterFunc(option, 'keywords');
      }
    } else {
      const findIndex = this.filters.findIndex((x) => x.type === 'keywords');
      this.filters[findIndex].option = [];
    }
    if (Object.keys(this.queryParams).includes('range')) {
      const find = this.mapAvailable.find((x) => x.value === 'range');
      if (find) {
        delete this.queryParams.available;
        const split = this.queryParams['range'].split(',');
        const sd = new Date(split[0]);
        const ed = new Date(split[1]);
        const findIndex = this.filters.findIndex(
          (x) => x.type === 'availability'
        );
        this.filters[findIndex].option = this.getAvailability({
          option: {
            id: find.id,
            displayName: find.displayName,
            type: 'availability',
          },
          range: {
            startDate: sd,
            endDate: ed,
          },
        });
      }
    } else {
      const findIndex = this.filters.findIndex(
        (x) => x.type === 'availability'
      );
      if (
        findIndex !== -1 &&
        this.filters[findIndex].option.some((x) => x.range)
      ) {
        this.filters[findIndex].option = [];
        this.availability = { option: { id: '-1', displayName: '', type: '' } };
      }
    }
    if (Object.keys(this.queryParams).includes('availability')) {
      delete this.queryParams.range;
      const find = this.mapAvailable.find(
        (x) => x.value === this.queryParams['availability']
      );

      if (find) {
        const findIndex = this.filters.findIndex(
          (x) => x.type === 'availability'
        );
        this.filters[findIndex].option = this.getAvailability({
          option: {
            id: find.id,
            displayName: find.displayName,
            type: 'availability',
          },
        });
      }
    } else {
      const findIndex = this.filters.findIndex(
        (x) => x.type === 'availability'
      );
      if (
        findIndex !== -1 &&
        this.filters[findIndex].option.some((x) => !x.range)
      ) {
        this.filters[findIndex].option = [];
        this.availability = { option: { id: '-1', displayName: '', type: '' } };
      }
    }
    this.setFilteringList();
  }

  setFilteringList() {
    this.selectedFilters = [];
    this.filters.forEach((x) => {
      this.selectedFilters = [...this.selectedFilters, ...x.option];
    });
    if (this.selectedNav === 'consultants') {
      this.contextService.globalFilterC.next(this.selectedFilters);
    }
    if (this.selectedNav === 'matching') {
      this.contextService.globalFilterM.next(this.selectedFilters);
    }
  }

  getWorkAs7N(value: boolean) {
    this.isChecked = value;
    if (value) {
      return [
        {
          id: uuidv4(),
          displayName: 'Worked with 7N',
          type: 'workedwith7n',
        },
      ];
    }

    return [];
  }
  async setWorkAs7N(value: boolean) {
    const findIndex = this.filters.findIndex((x) => x.type === 'workedwith7n');
    this.filters[findIndex].option = this.getWorkAs7N(value);

    this.queryParams.workedwith7n = JSON.stringify(value);

    if (!value) {
      delete this.queryParams.workedwith7n;
    }
    await this.router.navigate([], {
      queryParams: this.queryParams,
    });
  }

  getAvailability(availability: {
    option: option;
    range?: { startDate: Date; endDate: Date };
  }) {
    this.availability = availability;
    if (this.availability.range) {
      this.availability.option.range = availability.range;
      delete this.queryParams.availability;

      this.queryParams.range =
        this.helperFunctions.getDateISO8601(this.availability.range.startDate) +
        ',' +
        this.helperFunctions.getDateISO8601(this.availability.range.endDate);
    }
    if (this.availability.option.id !== '3available') {
      delete this.queryParams.range;
      const find = this.mapAvailable.find(
        (x) => x.id === availability.option.id
      );
      this.queryParams.availability = find?.value;
    }
    this.availability.option.type = 'availability';

    return [this.availability.option];
  }
  async setAvailability(availability: {
    option: option;
    range?: { startDate: Date; endDate: Date };
  }) {
    const findIndex = this.filters.findIndex((x) => x.type === 'availability');
    this.filters[findIndex].option = this.getAvailability(availability);

    await this.router.navigate([], {
      queryParams: this.queryParams,
    });
  }

  getFilterFunc(event: option[], filterType: string) {
    let type = filterType;

    const teamFilter = ['country', 'team', 'office', 'location'];
    if (teamFilter.includes(type)) {
      type = 'teamfilter';
    }
    const findIndex = this.filters.findIndex((x) => x.type === type);

    this.filters[findIndex].option = event;
    if (type === 'keywords') {
      const displayName = this.filters[findIndex].option.map(
        (x) => x.displayName
      );
      this.queryParams.keywords = displayName.join(',');
      if (displayName.length === 0) {
        delete this.queryParams.keywords;
      }
    }
    if (type === 'status') {
      const displayName = this.filters[findIndex].option.map(
        (x) => x.displayName
      );
      this.queryParams['status'] = displayName.join(',');
    }
    if (type === 'relationstatus') {
      const displayName = this.filters[findIndex].option.map(
        (x) => x.displayName
      );
      this.queryParams['relationstatus'] = displayName.join(',');
    }

    if (filterType === 'team') {
      const displayNameTeam = this.filters[findIndex].option
        .filter((x) => x.type === 'team')
        .map((x) => {
          return x.displayName;
        });
      if (filterType === 'team') {
        this.queryParams['team'] = displayNameTeam.join(',');
      }
    }

    if (filterType === 'country' || filterType === 'location') {
      const displayNameCountry = this.filters[findIndex].option
        .filter((x) => x.type === 'country')
        .map((x) => {
          return x.displayName;
        });
      this.queryParams['country'] = displayNameCountry.join(',');
    }
    if (filterType === 'office' || filterType === 'location') {
      const displayNameOffice = this.filters[findIndex].option
        .filter((x) => x.type === 'office')
        .map((x) => {
          return x.displayName;
        });
      this.queryParams['office'] = displayNameOffice.join(',');
    }
  }

  async selectedFiltersFunc(event: option[], filterType: string) {
    event.map((x) => {
      if (!x.type) {
        x.type = filterType;
      }
      if (filterType === 'keywords') {
        x.cvOrFiles = 0;
      }
      return x;
    });
    this.getFilterFunc(event, filterType);
    await this.router.navigate([], {
      queryParams: this.queryParams,
    });
  }
}
