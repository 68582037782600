<div class="filter-list" [class]="{ 'open-filter': modal, disable }">
  <ng-container *ngFor="let filter of filters; let i = index">
    <div *ngIf="filter.hide !== false">
      <div *ngIf="isFilterAvailable(filter.type)" class="filter body-large">
        <p class="bold">
          {{ filter.name }}
          <app-badge
            *ngIf="filter.option.length > 0 && [0, 3].includes(i)"
            [label]="filter.option.length + ''"
            color="neutral--Grey-900 textContrast amount"
          ></app-badge>
        </p>
        <p
          *ngIf="filter.option.length > 0"
          (click)="clearFilter(i)"
          class="body-small textLink clear"
        >
          Clear
        </p>
      </div>
      <div>
        <app-country
          [selected]="filters[0].option"
          [disabled]="!matchId || disable"
          (selectedOption)="selectedFiltersFunc($event, 'location')"
          [worklocation]="filterData ? filterData.workLocations : []"
          *ngIf="filter.type === 'location'"
        ></app-country>

        <app-keywords
          [disabled]="!matchId || filters[1].disable || disable"
          [selected]="filters[1].option"
          (selectedOption)="selectedFiltersFunc($event, 'keywords')"
          *ngIf="filter.type === 'keywords'"
        ></app-keywords>
        <app-availability
          [selected]="availability"
          [disabled]="disable"
          (selectedOption)="setAvailability($event)"
          *ngIf="filter.type === 'availability'"
        ></app-availability>
        <mat-slide-toggle
          [disabled]="!matchId || disable"
          [checked]="isChecked"
          (change)="setWorkAs7N($event.checked)"
          lable="yes"
          *ngIf="filter.type === 'workedwith7n'"
        >
          <p class="body-small">Yes</p>
        </mat-slide-toggle>
        <app-statuses
          [disabled]="!matchId || disable"
          [selected]="filters[4].option"
          (selectedOption)="selectedFiltersFunc($event, 'status')"
          [statuses]="filterData ? filterData.statuses : []"
          *ngIf="filter.type === 'status'"
        ></app-statuses>
        <app-tier-select
          [disabled]="!matchId || disable"
          [selected]="filters[5].option"
          (selectedOption)="selectedFiltersFunc($event, 'relationstatus')"
          [tiers]="filterData ? filterData.tiers : []"
          *ngIf="
            filter.type === 'relationstatus' && isFilterAvailable(filter.type)
          "
        ></app-tier-select>
        <app-team-filter
          [disabled]="!matchId || disable"
          [filter]="filters[6]"
          (selectedOptionTeam)="selectedFiltersFunc($event, 'team')"
          (selectedOptionLocation)="selectedFiltersFunc($event, 'location')"
          *ngIf="filter.type === 'teamfilter' && isFilterAvailable(filter.type)"
        ></app-team-filter>
        <!-- <app-client *ngIf="i === 2"></app-client> -->
        <!-- <mat-slide-toggle (change)="change.emit($event)" [(ngModel)]="isChecked"></mat-slide-toggle> -->
      </div>
    </div>
  </ng-container>
</div>
