import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { option } from 'src/interface/shared.interface';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss'],
})
export class KeywordsComponent {
  @Input() disabled: boolean;
  @Output() selectedOption = new EventEmitter<option[]>();
  @Input() selected: option[] = [];
  inputElement: HTMLInputElement;
  writing: string;

  createOnCheck() {
    if (this.writing) {
      this.selected.push({
        id: uuidv4(),
        displayName: this.writing,
        type: 'keywords',
      });
      this.writing = '';
      this.inputElement.innerText = this.writing;
      this.filter();
    }
  }

  createKeyword(event: Event) {
    this.inputElement = event.currentTarget as HTMLInputElement;
    this.writing = this.inputElement.innerText;
    if (this.writing) {
      this.selected.push({
        id: uuidv4(),
        displayName: this.writing,
        type: 'keywords',
      });
      this.writing = '';
      this.inputElement.innerText = this.writing;
      this.filter();
    }
  }

  filter() {
    this.selectedOption.emit(this.selected);
  }

  onKeyDown(event: KeyboardEvent): void {
    this.inputElement = event.currentTarget as HTMLInputElement;
    this.writing = this.inputElement.innerText;
  }

  removeFilter(i?: number) {
    if (!this.writing) {
      if (i !== undefined) {
        this.selected.splice(i, 1);
      } else {
        this.selected.splice(this.selected.length - 1);
      }
      this.filter();
    }
  }

  createOnEnter(event: KeyboardEvent): void {
    if (event.key === 'Backspace') {
      if (!this.writing) {
        this.removeFilter(this.selected.length - 1);
      }
      return;
    }
    if (event.key === 'Enter') {
      event.preventDefault();
      this.createKeyword(event);
      return;
    }
  }
}
